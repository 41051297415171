<template>
  <app-page-layout
    class="page-faq"
    :title="$t('page.faq.title')"
    :image="$t('page.faq.image')"
  >
    <template #header>
      <div class="page-faq__header"></div>
    </template>

    <div class="page-faq__container">
      <app-tabs
        :tabs="tabs"
        :active-tab="activeTab"
        @change-tab="changeTab"
        class="page-faq__tabs"
      >
        <label
          v-for="(item, index) in activeItems"
          :key="`${activeTabIndex}-${index}`"
          class="page-faq__item"
        >
          <input type="checkbox">
          <div class="page-faq__item-question">
            <div class="page-faq__item-question-title">{{ item?.question?.title }}</div>
            <div class="page-faq__item-question-text">
              {{ item?.question?.text }}
              <img class="page-faq__item-question-trigger" src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='m2 5 6 6 6-6'/%3E%3C/svg%3E" alt="">
            </div>
          </div>
          <div class="page-faq__item-answer">
            <div class="page-faq__item-answer-text" v-html="item?.answer?.text || ''" />
          </div>
        </label>
      </app-tabs>
    </div>
  </app-page-layout>
</template>

<script>
import AppPageLayout from '@/components/app-page-layout';
import AppTabs from '@/components/app-tabs';

export default {
  components: {
    AppPageLayout,
    AppTabs,
  },
  data() {
    return {
    };
  },
  computed: {
    tabs() {
      return this.$t('page.faq.data').map((thema, index) => ({
        ...thema,
        id: thema.id || `thema-${index + 1}`,
      }));
    },
    activeTabId() {
      return this.$route.params.thema || this.tabs[0].id;
    },
    activeTab() {
      return this.tabs.find(({ id }) => id === this.activeTabId);
    },
    activeTabIndex() {
      return this.tabs.indexOf(this.activeTab);
    },
    activeItems() {
      return this.activeTab?.items || [];
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ name: this.$route.name, params: { thema: tab.id } });
    },
  },
};
</script>

<style lang="scss">
.page-faq {
  &__header {
    height: 140px;
  }

  &__container {
    @include container;
  }

  &__tabs {
    padding-bottom: 36px;
  }

  &__item {
    display: block;
    margin-bottom: 25px;

    white-space: pre-line;

    input[type=checkbox] {
      display: none;
    }

    &-question {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.3);
      cursor: pointer;

      &-title {
        font-weight: 900;
        font-size: 28px;
        line-height: 95.5%;
        color: #CA1025;

        margin-bottom: 6px;
      }

      &-text {
        font-weight: 400;
        font-size: 26px;
        line-height: 138.19%;
        color: #000000;

        margin-bottom: 16px;
      }

      &-trigger {
        width: 0.8em;
        height: auto;
      }
    }

    &-answer {
      margin-top: 16px;
      display: none;

      ul {
        list-style-type: initial;
        list-style-position: outside;

        margin-left: 2em;
      }

      input:checked ~ & {
        display: block;
      }
    }
  }

  @include media-bp(tab) {
    &__item {
      &-question {
        &-title {
          font-size: 24px;
          margin-bottom: 8px;
        }
        &-text {
          font-size: 18px;
          margin-bottom: 8px;
        }
      }
    }

  }

  @include media-bp(mob) {
    &__header {
      height: 0px;
    }

    &__container {
      padding: 0;
    }

    &__item {
      @include container;

      margin-bottom: 12px;

      &-question {
        &-title {
          font-size: 24px;
          margin-bottom: 8px;
        }
        &-text {
          font-size: 18px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
