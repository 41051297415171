<template>
  <div class="app-tabs">
    <div class="app-tabs__menu">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        class="app-tabs__menu-item"
        :class="{ 'app-tabs__menu-item--active': isActive(tab) }"
        @click="changeTab(tab)"
      >
        <span class="app-tabs__menu-item-icon">
          <img v-if="tab.icon" :src="tab.icon" alt="ICON">
        </span>

        <span class="app-tabs__menu-item-title">{{ tab.title }}</span>
      </div>
    </div>

    <div v-if="$slots.default" class="app-tabs__tab">
      <slot />
    </div>

  </div>
</template>

<script>
export default {
  props: {
    tabs: { type: Array, required: true },
    activeTab: { type: Object },
  },
  mounted() {
    if (!this.activeTab && this.tabs.length) this.changeTab(this.tabs[0]);
  },
  methods: {
    changeTab(tab) {
      this.$emit('change-tab', tab);
    },
    isActive(tab) {
      return this.activeTab ? tab === this.activeTab : tab === this.tabs[0];
    },
  },
};
</script>

<style lang="scss">
.app-tabs {
  display: flex;
  align-items: flex-start;

  &__menu {
    flex: 0 0 375px;
    max-width: 375px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #f8f7f7;
    border-radius: 10px;

    &-item {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      padding: 26px;

      font-weight: 400;
      font-size: 22px;
      line-height: 138.19%;
      color: #46413C;

      cursor: pointer;

      border-bottom: 1px solid #E0E0E0;

      &:last-child {
        border-bottom: none;
      }

      &-icon {
        width: 26px;
        height: 29px;
        box-sizing: content-box;
        padding-right: 26px;

        img {
          width: 100%;
          height: 100%;;
          object-fit: contain;
          object-position: center;
        }
      }

      &--active &-title {
        color: #CA1025;
        text-decoration-line: underline;
      }
    }
  }

  &__tab {
    flex: 1 1 auto;
    max-width: 100%;
    padding-left: 62px;
    padding-right: 62px;
  }

  @include media-bp(tab) {
    &__menu {
      flex: 0 0 250px;
      max-width: 250px;

      &-item {
        padding: 12px;

        font-size: 18px;

        &-icon {
          width: 20px;
          height: 22px;
          padding-right: 12px;
        }
      }
    }

    &__tab {
      padding-left: 40px;
      padding-right: 20px;
    }
  }

  @include media-bp(mob) {
    width: 100%;
    flex-direction: column;

    &__menu {
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;

      &-item {
        padding: 26px;

        font-size: 22px;

        &-icon {
          width: 26px;
          height: 29px;
          padding-right: 26px;
        }
      }
    }

    &__tab {
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
      padding: 0;
      margin-top: 46px;
    }
  }
}
</style>
